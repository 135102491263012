<template>
  <img :src="imgLink" />
</template>

<script>
export default {
  name: "SingleImage",
  props: {
    imageURL: String,
  },
  data() {
    return {
      imgLink: "",
    };
  },
  methods: {
    async getImage() {
      const data = await import("../assets/products/" + this.imageURL)
        .then((result) => {
          return result.default;
        })
        .catch(() => {
          return require("../assets/images/404.jpg");
        });
      this.imgLink = data;
    },
  },
  created() {
    this.getImage();
  },
};
</script>

<style scoped></style>
