<template>
    <router-link :to="ProductDetailURL">
        <div class="product">
            <div class="thumbnail">
                <!--                <img :src="getImage(item.photo)">-->
                <SingleImage :imageURL="product.photo" :key="product.photo"/>
            </div>
            <div class="content">
                <label>{{ product.name }}</label>
                <small>{{ product.short_description }}</small>
            </div>
        </div>
    </router-link>
</template>

<script>
    import SingleImage from "./SingleImage";

    export default {
        name: "SingleProduct",
        components: {SingleImage},
        props: {
            product: {},
        },
        data() {
            return {};
        },
        methods: {},
        computed: {
            ProductDetailURL() {
                if (this.product.slug !== undefined) {
                    let url = {
                        name: 'ProductDetail',
                        params: {
                            slug: this.product.slug
                        }
                    };

                    return url;
                } else
                    return "";
            }
        },
    };
</script>

<style scoped lang="scss">
    section {
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
    }


    .product {
        display: inline-block;
        box-shadow: 0 0 16px #0002;
        cursor: pointer;
        margin: 0.4rem;
        margin-bottom: 1.5rem;
        transition: all ease-in-out 300ms;
        width: calc(100% / 3.15);
        background: white;
        height: 360px;
        overflow: hidden;

        .thumbnail {
            overflow: hidden;
            background: white;
            border: 1px solid #0003;

            img {
                width: 100%;
                height: 240px;
                object-fit: contain;
                transition: all ease-in-out 500ms;
            }
        }

        .content {
            padding: 1.5rem 1rem;
            background: white;
            cursor: pointer !important;

            label {
                font-size: 1.4rem;
                display: block;
                cursor: pointer !important;
                color: black;
            }

            p {
                font-size: 0.9rem;
            }

            small {
                display: block;
                font-weight: 300;
                margin-top: 0.5rem;
                color: $light-text-color;
                font-size: 0.8rem;
            }

            .heading {
                font-size: 0.6rem;
                font-weight: 500;
                text-transform: uppercase;
                letter-spacing: 0.05rem;
                color: $electric-blue;
                display: block;
                margin-bottom: 0.2rem;
            }
        }

        &:hover {
            .thumbnail {
                img {
                    transform: scale(1.2);
                }
            }
        }

        @media (max-width: 768px) {
            width: 100%;
        }
    }
</style>
